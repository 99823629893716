import firebase from 'firebase/app'

import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyDTuokjA88U7iTmyC0KZh9fSKnTlmTEHLQ",
    authDomain: "alexispoveda.firebaseapp.com",
    databaseURL: "https://alexispoveda.firebaseio.com",
    projectId: "alexispoveda",
    storageBucket: "alexispoveda.appspot.com",
    messagingSenderId: "433690345805",
    appId: "1:433690345805:web:d827994c3cf7692f0d3704"
};

export default firebase.initializeApp(firebaseConfig)

export const db = firebase.database();